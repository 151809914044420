<template>
  <b-modal v-model="modal" hide-footer size="lg">
    <template #modal-title>
      <h5>CAMBIAR FASE</h5>
    </template>
    <div>
      <div class="d-flex justify-content-center">
        <multiselect v-model="phase" track-by="name" label="name" placeholder="Selecciona una fase" :options="getPhases"
          :searchable="false" :allow-empty="false"></multiselect>
      </div>
      <div v-if="phase" class="mt-4 mx-2">
        <p><strong>{{ $i18n.t(`phases_definitions.${phase.key}`) }}</strong></p>
        <div class="mt-4 d-flex justify-content-center">
          <vs-button @click="sendPhase" :loading="isSending">ENVIAR</vs-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect'

export default {
  components: { Multiselect },
  props: {
    game: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      modal: false,
      phase: null,
      isSending: false
    }
  },
  computed: {
    ...mapGetters({
      getPhases: 'wheel/getPhases',
      success: 'wheel/getSuccess'
    })
  },
  methods: {
    async sendPhase() {
      this.isSending = true

      const { providerId } = this.game

      if (!providerId) return this.$swal.fire('Este juego no tiene proveedor', '', 'info')

      await this.changePhase({ providerId, phase: this.phase.key })

      if (!this.success) return this.$swal.fire('Error cambiando la fase', '', 'error')

      this.$swal.fire('Fase cambiada', '', 'success')
      this.isSending = false
      this.phase = null
      this.modal = false
    },
    ...mapActions({
      changePhase: 'wheel/changePhase'
    })
  },
}
</script>

<style lang="scss" scoped></style>