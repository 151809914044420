<template>
  <div class="numbers">
    <div v-for="(item, i) in numbers" :key="i" class="numbers-item" :style="`background-color: ${colors[item]}`"
      @click="$emit('handleResult', item)">
      {{ item }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      numbers: [1, 2, 5, 8, 10, 15, 20, 30, 40],
      colors: {
        1: '#472524',
        2: '#c7dc27',
        5: '#d83438',
        8: '#6fe338',
        10: '#167c2d',
        15: '#ed8047',
        20: '#a252cb',
        30: '#d42566',
        40: '#b8b187',
      },
    }
  }
}
</script>

<style lang="scss" scoped>
.numbers {
  display: grid;
  grid-template-columns: repeat(3, 33.33%);
  grid-auto-rows: 200px;
  width: 100%;
}

.numbers-item {
  height: 90%;
  /* background-color: blueviolet; */
  width: 90%;
  justify-self: center;
  align-self: center;
  border-radius: 10px;
  border-style: ridge;
  border-width: 15px;
  text-align: center;
  line-height: 2;
  font-size: 4rem;
  color: white;
  font-weight: bolder;
  box-shadow: inset 0px 0px 20px 12px rgba(0, 0, 0, 0.75);
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.788);
  border-color: #c9c9c9;
}
</style>