<template>
  <Layout>
    <PageHeader
      :title="title"
      moda_title="Datos de la mesa"
      ref="pageForm"
      :items="items"
      :isAdmin="isAdmin">
      <template>
        <PageForm :typeform="typeform" @closeModa="closeModa" />
      </template>
    </PageHeader>
    <ChangePhase ref="changePhaseComponent" :game="game" />
    <JackpotBank
      ref="jackpotBankComponent"
      :game="game"
      @reload="reloadJackpot" />
    <OpeningClosing
      ref="openingClosingComponent"
      :game="game"
      :type="gamesTypes.WheelFortune"
      @closeModal="fetchWheels" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    {{ $t("filter.show") }}&nbsp;
                    <vs-select v-model="perPage" color="dark" class="shadow-lg">
                      <vs-option
                        v-for="(op, index) in pageOptions"
                        :key="index"
                        :label="op"
                        :value="op"
                        >{{ op }}</vs-option
                      >
                    </vs-select>
                    &nbsp;{{ $t("filter.entries") }}
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center">
                    {{ $t("filter.search") }}:
                    <vs-input
                      v-model="filter"
                      type="search"
                      placeholder="Buscar..."
                      primary
                      class="shadow-lg"></vs-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <div class="table-responsive mb-0">
              <b-table
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn">
                <template #cell(logo)="data">
                  <b-img
                    :src="data.item.logo ? data.item.logo : 'NOIMATOCHARGE'"
                    height="80px"
                    class="logo-img"
                    @error="handleError"></b-img>
                </template>
                <template #cell(manualDisable)="data">
                  <vs-button @click="disableWheel(data.item)">
                    {{ data.item.manualDisable ? "ACTIVAR" : "DESACTIVAR" }}
                  </vs-button>
                </template>
                <template #cell(actions)="data">
                  <div class="action-btn-container">
                    <b-dropdown
                      variant="link"
                      no-caret
                      class="position-absolute">
                      <template #button-content>
                        <i class="fa fa-fw fa-bars font-size-16" />
                      </template>
                      <div v-if="isAdmin">
                        <b-dropdown-item @click="edit(data.item)">
                          <i class="uil-edit m-2 font-size-18" />
                          <span class="align-middle ml-5">EDITAR</span>
                        </b-dropdown-item>
                      </div>
                      <div v-if="isAdmin">
                        <b-dropdown-item @click="setOpeningClosing(data.item)">
                          <i class="uil-calendar-alt m-2 font-size-18" />
                          <span class="align-middle ml-5"
                            >APERTURA / CIERRE</span
                          >
                        </b-dropdown-item>
                      </div>
                      <!-- <div v-if="isAdmin">
                        <b-dropdown-item @click="changePhase(data.item)">
                          <i class="uil-edit m-2 font-size-18" />
                          <span class="align-middle ml-5">CAMBIAR FASE</span>
                        </b-dropdown-item>
                      </div> -->
                      <div v-if="isAdmin">
                        <b-dropdown-item @click="removeWheel(data.item.uuid)">
                          <i class="mdi mdi-delete-outline m-2 font-size-18" />
                          <span class="align-middle ml-5">ELIMINAR</span>
                        </b-dropdown-item>
                      </div>
                      <div>
                        <b-dropdown-item @click="play(data.item)">
                          <i
                            class="mdi mdi-google-controller m-2 font-size-18" />
                          <span class="align-middle ml-5">JUGAR</span>
                        </b-dropdown-item>
                      </div>
                      <div>
                        <b-dropdown-item @click="goJackpotBank(data.item)">
                          <i class="uil-money-stack m-2 font-size-18" />
                          <span class="align-middle ml-5"
                            >VER BANCO DE JACKPOT</span
                          >
                        </b-dropdown-item>
                      </div>
                    </b-dropdown>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import { ChangePhase, PageForm } from "./components";
import { breadCrumbData } from "@/helpers/breadcrumbs/breadCrumbs";
import { mapGetters, mapActions } from "vuex";
import { JackpotBank, OpeningClosing } from "../components";
import { mapMutations } from "vuex/dist/vuex.common.js";

export default {
  components: {
    Layout,
    PageHeader,
    PageForm,
    ChangePhase,
    JackpotBank,
    OpeningClosing,
  },
  data() {
    return {
      title: "Rueda",
      items: breadCrumbData.sprintWheels,
      typeform: {},
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      currentPage: 1,
      sortBy: "desc",
      sortDesc: true,
      filterOn: ["name"],
      game: {},
    };
  },
  computed: {
    ...mapGetters({
      fields: "wheel/getTableHeaders",
      tableData: "wheel/getWheels",
      success: "wheel/getSuccess",
      isAdmin: "user/isAdmin",
      getWheel: "wheel/getCurrent",
      gamesTypes: "reports/gamesTypes",
    }),
  },
  methods: {
    async disableWheel(game) {
      const action = game.manualDisable ? "activar" : "desactivar";

      const { isConfirmed } = await this.$swal.fire({
        title: `Estas seguro de ${action} la rueda?`,
        icon: "info",
        showDenyButton: true,
      });

      if (!isConfirmed) return;

      await this.manualDisable(game.uuid);

      if (!this.success) return this.$swal.fire(`Error ${action}`, "", "info");

      this.$swal.fire("Operacion realizada", "", "success");
      this.fetchWheels();
    },
    edit(item) {
      this.typeform = { ...item };
      this.$refs.pageForm.modal = true;
    },
    async removeWheel(uuid) {
      try {
        await this.remove(uuid);
        if (!this.success) throw new Error("Error creando el juego");
        this.$swal.fire({
          title: "Creado",
          icon: "success",
        });
      } catch (error) {
        console.log("ERROR REMOVE WHEEL", error);
        this.$swal.fire({
          title: "Error eliminando el juego",
          icon: "error",
        });
      }
    },
    closeModa() {
      this.$refs.pageForm.modal = false;
      this.typeform = {};
      this.fetchWheels();
    },
    handleError(e) {
      e.target.src = "https://via.placeholder.com/150";
    },
    async play(item) {
      this.$router.push({
        name: "sprint-wheels-play",
        params: {
          id: item.uuid,
          game: item,
        },
      });
    },
    changePhase(item) {
      this.game = item;
      this.$refs.changePhaseComponent.modal = true;
    },
    async goJackpotBank(game) {
      this.game = game;
      this.$refs.jackpotBankComponent.bank = game.bank;
      this.$refs.jackpotBankComponent.modal = true;
    },
    async reloadJackpot(game) {
      this.setFetching(true);
      await this.getOne(game.uuid);
      this.setFetching(false);
      const { bank } = this.getWheel;
      this.$refs.jackpotBankComponent.bank = bank || 0;
      this.fetchWheels();
    },
    async setOpeningClosing(item) {
      this.game = item;
      this.$refs.openingClosingComponent.openingTime = item.openingTime;
      this.$refs.openingClosingComponent.closingTime = item.closingTime;
      this.$refs.openingClosingComponent.alwaysOpen = item.alwaysOpen;
      this.$refs.openingClosingComponent.modal = true;
    },
    ...mapMutations({
      setFetching: "games/setFetching",
    }),
    ...mapActions({
      fetchWheels: "wheel/fetchWheels",
      remove: "wheel/remove",
      getOne: "wheel/getOne",
      update: "wheel/update",
      manualDisable: "wheel/manualDisable",
    }),
  },
  mounted() {
    this.fetchWheels();
  },
};
</script>
