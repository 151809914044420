var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Payments',{ref:"betPayments",attrs:{"game":_vm.typeform,"bet-payments":_vm.payments},on:{"savePayments":_vm.savePayments}}),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.save()}}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Mesa Fisica")]),_c('div',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.typeform.providerId),expression:"typeform.providerId"}],ref:"rouletteFisicSelect",staticClass:"form-select border-0 shadow-lg",class:{
                'is-invalid': _vm.typesubmit && _vm.$v.typeform.providerId.$error,
              },attrs:{"name":"providerId","placeholder":"Seleccione.."},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.typeform, "providerId", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"disabled":"","selected":""}},[_vm._v(_vm._s(_vm.$t("helpers.select"))+"...")]),_vm._l((_vm.gameFisic),function(item,index){return _c('option',{key:index,domProps:{"value":item,"selected":_vm.typeform.providerId == item}},[_vm._v(" "+_vm._s(item)+" ")])})],2),(_vm.typesubmit && _vm.$v.typeform.providerId.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.providerId.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()])])]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Nombre")]),_c('vs-input',{staticClass:"shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.name.$error,
            },attrs:{"placeholder":"Nombre","border":""},model:{value:(_vm.typeform.name),callback:function ($$v) {_vm.$set(_vm.typeform, "name", $$v)},expression:"typeform.name"}}),(_vm.typesubmit && _vm.$v.typeform.name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.name.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Logo")]),_c('vs-input',{staticClass:"shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.logo.$error,
            },attrs:{"placeholder":"Logo","border":""},model:{value:(_vm.typeform.logo),callback:function ($$v) {_vm.$set(_vm.typeform, "logo", $$v)},expression:"typeform.logo"}}),(_vm.typesubmit && _vm.$v.typeform.logo.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.logo.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Imagen de fondo")]),_c('vs-input',{staticClass:"shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.imgBackground.$error,
            },attrs:{"placeholder":"url background","border":""},model:{value:(_vm.typeform.imgBackground),callback:function ($$v) {_vm.$set(_vm.typeform, "imgBackground", $$v)},expression:"typeform.imgBackground"}}),(_vm.typesubmit && _vm.$v.typeform.imgBackground.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.imgBackground.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Color de fondo")]),_c('vs-input',{staticClass:"shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.colorBackground.$error,
            },attrs:{"placeholder":"color background","border":"","type":"color"},model:{value:(_vm.typeform.colorBackground),callback:function ($$v) {_vm.$set(_vm.typeform, "colorBackground", $$v)},expression:"typeform.colorBackground"}}),(_vm.typesubmit && _vm.$v.typeform.colorBackground.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.colorBackground.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("URL de transmision")]),_c('vs-input',{staticClass:"shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.urlTransmision.$error,
            },attrs:{"placeholder":"nano cosmos","border":""},model:{value:(_vm.typeform.urlTransmision),callback:function ($$v) {_vm.$set(_vm.typeform, "urlTransmision", $$v)},expression:"typeform.urlTransmision"}}),(_vm.typesubmit && _vm.$v.typeform.urlTransmision.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.urlTransmision.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("URL para Launch")]),_c('vs-input',{staticClass:"shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.launchURL.$error,
            },attrs:{"placeholder":"https://front.com","border":""},model:{value:(_vm.typeform.launchURL),callback:function ($$v) {_vm.$set(_vm.typeform, "launchURL", $$v)},expression:"typeform.launchURL"}}),(_vm.typesubmit && _vm.$v.typeform.launchURL.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.launchURL.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("URL para volver al lobby")]),_c('vs-input',{staticClass:"shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.lobby.$error,
            },attrs:{"placeholder":"https://api.com","border":""},model:{value:(_vm.typeform.lobby),callback:function ($$v) {_vm.$set(_vm.typeform, "lobby", $$v)},expression:"typeform.lobby"}}),(_vm.typesubmit && _vm.$v.typeform.lobby.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.lobby.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Tiempo de apuesta")]),_c('vs-input',{staticClass:"shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.betTime.$error,
            },attrs:{"placeholder":"color background","border":""},model:{value:(_vm.typeform.betTime),callback:function ($$v) {_vm.$set(_vm.typeform, "betTime", $$v)},expression:"typeform.betTime"}}),(_vm.typesubmit && _vm.$v.typeform.betTime.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.betTime.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Time 1")]),_c('vs-input',{staticClass:"shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.timeOne.$error,
            },attrs:{"placeholder":"TIME 1 CAMERA","border":""},model:{value:(_vm.typeform.timeOne),callback:function ($$v) {_vm.$set(_vm.typeform, "timeOne", $$v)},expression:"typeform.timeOne"}}),(_vm.typesubmit && _vm.$v.typeform.timeOne.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.timeOne.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Time 2")]),_c('vs-input',{staticClass:"shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.timeTwo.$error,
            },attrs:{"placeholder":"TIME 2 CAMERA","border":""},model:{value:(_vm.typeform.timeTwo),callback:function ($$v) {_vm.$set(_vm.typeform, "timeTwo", $$v)},expression:"typeform.timeTwo"}}),(_vm.typesubmit && _vm.$v.typeform.timeTwo.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.timeTwo.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Time 3")]),_c('vs-input',{staticClass:"shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.timeThree.$error,
            },attrs:{"placeholder":"TIME 3 CAMERA","border":""},model:{value:(_vm.typeform.timeThree),callback:function ($$v) {_vm.$set(_vm.typeform, "timeThree", $$v)},expression:"typeform.timeThree"}}),(_vm.typesubmit && _vm.$v.typeform.timeThree.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.timeThree.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Tiempo adicional")]),_c('vs-input',{staticClass:"shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.aditionalTime.$error,
            },attrs:{"placeholder":"TIEMPO ADICIONAL","border":""},model:{value:(_vm.typeform.aditionalTime),callback:function ($$v) {_vm.$set(_vm.typeform, "aditionalTime", $$v)},expression:"typeform.aditionalTime"}}),(_vm.typesubmit && _vm.$v.typeform.aditionalTime.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.aditionalTime.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Tiempo para iniciar ronda")]),_c('vs-input',{staticClass:"shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.startRoundTime.$error,
            },attrs:{"placeholder":"TIEMPO PARA INICIAR RONDA","border":""},model:{value:(_vm.typeform.startRoundTime),callback:function ($$v) {_vm.$set(_vm.typeform, "startRoundTime", $$v)},expression:"typeform.startRoundTime"}}),(_vm.typesubmit && _vm.$v.typeform.startRoundTime.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.startRoundTime.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('label',[_vm._v("BANCO DE JACKPOTS")]),_c('vs-input',{staticClass:"shadow-lg",attrs:{"placeholder":"1000","type":"number","name":"jackpotBank","step":"any"},model:{value:(_vm.typeform.bank),callback:function ($$v) {_vm.$set(_vm.typeform, "bank", $$v)},expression:"typeform.bank"}})],1),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v(" MAX. APUESTAS POR SIMBOLOS: ")]),_c('vs-input',{staticClass:"shadow-lg",attrs:{"placeholder":"Ej: 6","border":""},model:{value:(_vm.typeform.maxBetFigures),callback:function ($$v) {_vm.$set(_vm.typeform, "maxBetFigures", $$v)},expression:"typeform.maxBetFigures"}})],1)]),_c('b-col',{attrs:{"sm":"12","md":"12"}},[_c('vs-button',{attrs:{"type":"button"},on:{"click":_vm.modifyPayments}},[_vm._v(" PAGOS ")])],1)],1),_c('div',{staticClass:"mb-3 mt-3"},[_c('div',{staticClass:"d-flex"},[_c('vs-button',[_vm._v("Guardar")]),_c('vs-button',{attrs:{"success":"","type":"button"},on:{"click":function($event){return _vm.$emit('closeModa')}}},[_vm._v(" Cancelar ")])],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }